import React from "react";
import '../styles/App.css';
import Button from "../components/Button";
import Testimonials from "../components/Temoinages";
import Bienfait from "../components/BienfaitDuYoga";
import Citation from "../components/Citation";
import GridLayout from "../components/GridLayout";
import Tarifs from "../components/Tarifs";
import ParallaxImage from "../components/ParallaxImage";

const Accueil = () => {
  return (
    <>
      <div className="flex flex-col justify-center items-center h-[auto]">
        {/* section hero */}
        <div className="p-4 lg:px-[5%]  2xl:px-[25%] mt-[50%] items-center lg:mt-[200px]  text-center gap-[80px] flex align-middle flex-col">
          <div className="animate__animated animate__slideInDown flex flex-col gap-6 lg:w-[60%] mx-[auto] ">
          <h1 className="text-[1.7rem] md:text-[3.2rem] lg:leading-[2.8rem] font-extrabold">
  Le <span className="animate-bounce bg-gradient-to-br from-blue-100 to-pink-50 text-blue-500 py-2 px-8 rounded-full text-[1.7rem] md:text-[2.8rem] lg:leading-[2.2rem] font-extrabold">yoga aérien</span>, une{" "}
  <span className="text-[1.7rem] md:text-[3.2rem] lg:leading-[4.2rem] font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-blue-500 to-green-500">
    expérience
  </span>{" "}
  unique pour le corps et l’esprit.
</h1>
            <h2 className="text-gray-600 text-xl ">
            Après avoir surmonté de nombreux déﬁs, notamment cinq opérations aux genoux, j'ai trouvé un véritable soulagement dans le yoga aérien. Cette pratique m'aide à mieux gérer mes douleurs, mon stress et mes émotions
            </h2>

            <div className="">
              <Button
                label={"Réservez une séance"}
                customLink={"https://app.acuityscheduling.com/schedule.php?owner=33466547"}
              />
            </div>
          </div>

          <div className="animate__animated animate__slideInUp">
            <img
              src="./assets/image--drag-in-to-fill@2x.png"
              alt="yoga-studio"
            />
          </div>
        </div>

        {/* section temoinages */}

        <div className="">
          <Testimonials />
        </div>

        {/* Gird section decouvrez */}
        <div className="bg-[#EDF2F5] px-4 lg:px-[5%] lg:py-[5%] py-10 2xl:px-[25%]">
          <div className="gap-[80px] lg:gap-[80px] flex flex-col">
            <div className="grid lg:grid-cols-4 items-start lg:space-x-8 right-block">
              <div className=" col-span-2 flex flex-col gap-[50px] mb-8 lg:mb-0 ">
                <div className="flex flex-col gap-4">
                  <h1 className="text-[1.6rem] md:text-[2.6rem] font-bold">
                  Mon approche unique ?
                  </h1>
                  <p className="text-gray-600 text-xl lg:w-[80%] leading-[2rem]">
                  Des cours limités à 5 personnes, pour une attention personnalisée et une pratique en toute sécurité.
Rejoignez-moi chaque matin de <span className="font-bold text-xl">10h à 11h ou chaque soir de 19h à 20h</span>, même le week-end, pour seulement <span className="font-bold text-xl"> 15 €</span> la séance, 
<span className="font-bold text-xl"> avenue d'Ophem, 75, 1970 Wezembeek-Oppem.</span>
Découvrez comment le yoga aérien peut transformer votre bien-être.
                  </p>
                </div>
                <div>
                  <Button label={"Découvrez le yoga aérien"} customLink={"https://aerialyogaexperience.as.me/"} />
                </div>
              </div>
              <div className=" col-span-2 ">
                <img
                  className="w-full h-full object-cover rounded-lg"
                  src="./assets/image@2x.png"
                  alt="Découvrez le Yoga Aérien"
                />
              </div>
            </div>

            {/* mes horraires */}
<div className=" bg-blue-200 rounded-xl w-full  px-4  py-[8%] lg:mt-[100px] mt-[20%]">
  <div className="lg:w-[60%] mx-[auto] flex flex-col justify-center items-center text-center">
          <h2 className="text-3xl font-bold lg;mr-10 mb-10 up-block ">Mes horaires</h2>
          <ul className=" text-gray-700 flex flex-row justify-center up-block items-center flex-wrap gap-8">
            <li className="text-xl lg:w-[50%] leading-loose font-medium  px-6 py-4 shadow-lg up-block bg-white rounded-3xl">Tous les matins de <span className="text-2xl font-bold text-blue-700">10h à 11h</span></li>
            <li className="text-xl lg:w-[50%] leading-loose font-medium px-6 py-4 shadow-lg up-block bg-white rounded-3xl">Tous les soirs de <span className="text-2xl font-bold text-blue-700">19h à 20h</span></li>
          </ul>
        </div>
        </div>

            {/* ----------- */}

            <div className="grid lg:grid-cols-4 items-start lg:space-x-8 left-block">
              <div className=" col-span-2 flex flex-col gap-[50px]  mb-8 lg:hidden">
                <div className="flex flex-col gap-4">
                  <h1 className="text-[1.6rem] md:text-[2.6rem] font-bold">
                  Yoga Aérien vs. Yoga au Sol
                  </h1>
                  <p className="text-gray-600 text-xl lg:w-[80%] leading-[2rem]">
                  Le yoga au sol repose principalement sur la force musculaire et la gravité, tandis que le yoga aérien s'appuie sur un hamac qui soutient le corps.
                  </p>
                  <p className="text-gray-600 text-xl lg:w-[80%] leading-[2rem]">
                  Avec le yoga aérien, moins de pression est exercée sur les articulations et le dos, ce qui permet de pratiquer en douceur, surtout en cas de douleurs ou de restrictions physiques. Vous proﬁtez d'une plus grande liberté de mouvement, des étirements plus profonds, et un meilleur alignement postural.
                 </p>
               
                </div>
                
              </div>

              <div className=" col-span-2 mb-6 lg:mb-0">
                <img
                  className="w-full h-full object-cover rounded-lg"
                  src="./assets/image-31@2x.png"
                  alt="Découvrez le Yoga Aérien"
                />
              </div>

              <div className=" col-span-2 flex flex-col gap-[50px]  mb-8 max-md:hidden">
                <div className="flex flex-col gap-4">
                  <h1 className="text-[1.6rem] md:text-[2.6rem] font-bold">
                  Yoga Aérien vs. Yoga au Sol
                  </h1>
                  <p className="text-gray-600 text-xl leading-[2rem]">
                  Le yoga au sol repose principalement sur la force musculaire et la gravité, tandis que le yoga aérien s'appuie sur un hamac qui soutient le corps.
                  </p>
                  <p className="text-gray-600 text-xl  leading-[2rem]">
                  Avec le yoga aérien, moins de pression est exercée sur les articulations et le dos, ce qui permet de pratiquer en douceur, surtout en cas de douleurs ou de restrictions physiques. Vous proﬁtez d'une plus grande liberté de mouvement, des étirements plus profonds, et un meilleur alignement postural. Et bien sûr, une sensation de légèreté incomparable qui libère le corps et l'esprit !
                 </p>
               
                </div>
                
              </div>
            </div>
          </div>
        </div>

        {/* Bienfaits du yoga */}
        <Bienfait />
        {/* Citation de yoga */}
        <Citation />
        {/* Bienfaits du yoga */}
        <GridLayout />
        {/* Citation de yoga */}
        <Citation />
        {/* Tarif */}
        <Tarifs />
        {/* ParallaxImage*/}
        <ParallaxImage />
      </div>
    </>
  );
};

export default Accueil;
