import React from 'react';

const Tarifs = () => {
  const plans = [

    {
      module:'Standard',
      price: '15€',
      priceTag: "par séance",
      features: [
        'Accès aux cours d\'initiation',
        'Matériel fourni',
        'Accès à la communauté en ligne',
      ],
      buttonText: 'Souscris',
      buttonStyle:'bg-blue-300 ',
      buttonUrl:'https://buy.stripe.com/6oEcQcbPhf5vfZe9AR',
      cardStyle:' border p-8 flex flex-col justify-between rounded-lg shadow-lg bg-blue-100 left-block',
    },
    {
      module:'intermediare',
      price: '150€',
      priceTag: '4 mois',
      features: [
        '10 séances en 4 mois',
        'Accès aux cours d\'initiation',
        'Matériel fourni',
        'Accès à la communauté en ligne',
      ],
      buttonText: 'Souscris',
      buttonStyle:'bg-blue-700',
      buttonUrl:'https://buy.stripe.com/14k2by5qT0aBaEUeV1',
      cardStyle:' border p-8 flex flex-col justify-between rounded-lg shadow-lg up-block',
    },
    
    {
      module:'Avancé',
      price: '100€',
      priceTag: 'par mois',
      features: [
        'Accès à tous les cours 3 fois par semaine',
        'Matériel fourni',
        'Accès à la communauté en ligne',
        'Séances de méditation guidée',
      ],
      buttonText: 'Souscris',
      buttonStyle:'bg-blue-700',
      buttonUrl:'https://buy.stripe.com/dR6bM81aD1eFfZe8wJ',
      cardStyle:' border p-8 flex flex-col justify-between rounded-lg shadow-lg up-block',
    },
  ];

  return (
    <div id='tarif' className="px-4 py-10 lg:px-[5%] lg:py-[10%] 2xl:px-[25%]">
      <div className='mb-20 up-block'>
      <h1 className="text-4xl md:text-[2.6rem] font-bold mb-4 text-center">Nos Tarifs</h1>
      <p className="text-lg mb-8 text-center">Des forfaits adaptés à vos besoins et votre budget</p>
      </div>
      <div className="grid md:grid-cols-3 gap-[10px]">
        {plans.map((plan, index) => (
          <div key={index} className={`${plan.cardStyle} `}>

            <div>
            <h1 className='text-md font-bold uppercase'>{plan.module}</h1>
            <div className='flex items-end my-4 '>
            <h2 className="text-3xl font-extrabold pr-2 text-blue-700 up-block">{plan.price}</h2>
             <h3 className='text-sm font-semibold'>{plan.priceTag}</h3>
            </div>
            </div>
            <ul className="mb-4 flex flex-col justify-between gap-2 px-2">
              {plan.features.map((feature, idx) => (
                <li key={idx} className="mb-2">- {feature}</li>
              ))}
            </ul>
            <a href={plan.buttonUrl} className={ plan.buttonStyle + " text-white font-extrabold justify-center items-center bg-blue-700 flex py-2 px-4 rounded-xl hover:bg-blue-800 hover:cursor-pointer  "}>
              {plan.buttonText}
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Tarifs;
