import React from "react";
const Footer = () => {


  return (
    <div className=" h-auto text-left  ">
      <div className=" w-[90%] mx-[auto] border-t-2 border-gray-200"></div>

      <div className=" lg:grid lg:grid-cols-2 p-4 lg:px-[5%]  2xl:px-[25%] mx-auto px-5 py-10 lg:py-[100px] items-center">
        <div className=" col-span-1 flex flex-col gap-8">
          <div className="flex items-center">
            <img className="lg:w-[25%] pr-4" src="./assets/aerial.png" alt="" srcset="" />
            <h1 className="text-lg md:text-lg lg:text-xl">Notre <br /> localisation</h1>
          </div>

        </div>

        <div className="col-span-1 mt-8 lg:mt-0 lg:justify-end lg:items-end flex flex-col">
          <div>

<p className="text-lg md:text-lg lg:text-xl p-2">Avenue d'Ophem 75 1970 Wezembeek-Oppem</p>
<p className="text-lg md:text-lg lg:text-xl p-2">aerialyogaexperience@gmail.com</p>
          </div>
        </div>
      </div>

      <div className="bg-blue-700 ">
        <div className="items-center text-center text-sm py-5 text-white font-bold">
          © 2024 aerial yoga experience. All rights reserved
        </div>
      </div>
    </div>
  );
};

export default Footer;
