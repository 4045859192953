import React from 'react';
import Button from "../components/Button";

const ParallaxImage = () => {
 
  return (
    <div className="bg-blue-100 px-4 py-8 lg:p-[10%] w-full">
      <div className="newsletter-container w-fit flex flex-col justify-center mx-auto">
        <div className="form-container flex flex-col gap-4 up-block">
          <h2 className="text-[2rem] md:text-[2.6rem] font-extrabold mb-4">Inscrivez-vous à notre newsletter</h2>
          <div className='flex flex-col gap-4 mx-auto'>
            <Button
            label={"S'inscrire dès maintenant"}
            customLink={"https://mailchi.mp/ea33f15c37fb/des-nouvelles-du-studio"}
          />
          </div>
         
        </div>
      </div>
    </div>
  );
};

export default ParallaxImage;
