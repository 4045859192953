// src/components/common/Button.js
import React from "react";

const Button = ({ label, customLink, customStyle }) => {
  return (
    <a
      href={customLink}
      className="bg-blue-700 rounded-lg px-6 py-4 text-sm lg:text-md font-medium w-fit active:scale-[1.03] text-white justify-center items-center hover:bg-blue-500"
    >
      {label}
    </a>
  );
};

export default Button;
