import React from "react";


const Bienfait = () => {
  let Gridlayout = [
    {
      Header: "Réduction du stress :",
      paragraph:
        "Le yoga incorpore des techniques de respiration et de méditation qui aident à calmer l'esprit et à réduire le stress.",
    },
    {
      Header: "Amélioration de la concentration :",
      paragraph:
        "La pratique du yoga encourage la pleine conscience et la concentration mentale.",
    },
    {
      Header: "Réduction de la tension artérielle :",
      paragraph:
        "Le yoga aide à abaisser la pression artérielle en favorisant la relaxation et en améliorant la circulation.",
    },
    {
      Header: "Amélioration de la qualité du sommeil :",
      paragraph:
        "La pratique régulière du yoga peut aider à réguler les cycles de sommeil et à améliorer la qualité du repos.",
    },
    {
      Header: "Renforcement du système immunitaire :",
      paragraph:
        "Le yoga stimule le système lymphatique et favorise la circulation, aidant ainsi le corps à éliminer les toxines.",
    },
    {
      Header: "Gestion de la douleur :",
      paragraph:
        "Le yoga peut aider à soulager les douleurs chroniques en améliorant la flexibilité, la force et la relaxation.",
    },
    {
      Header: "Amélioration de l'humeur :",
      paragraph:
        "Les endorphines libérées pendant la pratique du yoga peuvent améliorer l'humeur et aider à combattre la dépression.",
    },
    {
      Header: "Soutien à la santé mentale :",
      paragraph:
        "La pratique du yoga est bénéfique pour réduire l'anxiété, la dépression et autres troubles mentaux grâce à ses effets apaisants sur le système nerveux.",
    },
  ];

  return (
    <div className="px-4 py-10 lg:py-[10%] lg:px-[10%] 2xl:px-[25%]">
      <div className="h-auto lg:w-2/3 mx-auto text-center justify-center pb-10 lg:pb-20 up-block ">
        <h2 className="text-[1.8rem] md:text-[2.6rem] font-extrabold text-center lg:text-center">
          {" "}
          Autres bienfaits du yoga
        </h2>
        <p className="text-gray-400 text-xl lg:text-center">
        yoga pour tous
        </p>
      </div>

      {/* Grid layout section */}
      <div className="lg:grid lg:grid-cols-2 lg:grid-row-auto gap-8">
        {Gridlayout.map((card, index) => (
          <div key={index} className=" lg:mx-[auto] mb-10 up-block p-6 bg-pink-100 rounded-xl">
            <div className="">
              <h1 className="text-2xl font-medium mb-3">{card.Header}</h1>
              <p className=" text-gray-400 text-lg text-left"> {card.paragraph} </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Bienfait;
