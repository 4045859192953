import React from 'react';

const testimonials = [
 
  {
    text: "Merci à Céline Thuysbaert pour les merveilleuses photos du site web. Suivez-la sur Instagram !",
    author: "Céline Thuysbaert"
  },
 
  {
    text: "Excellent cours de Yoga aérien. Professeur à l'écoute qui corrige les postures.",
    author: "Colette"
  },
  {
    text: "Super expérience que celle de se retrouver en apesanteur avec Alice, qui accompagne et sécurise mes mouvements avec beaucoup de professionnalisme. Tout le corps travaille intensément pendant que mon esprit s'amuse. Verdict : Aero-yogini ravie !",
    author: "Marie Dupont"
  },
];

const Testimonials = () => {


  return (
    <div className="px-4 lg:px-[5%] py-[5%] 2xl:px-[25%]">
     
        <div className='up-block'>
        <h1 className='text-[1.8rem] md:text-[2.6rem] font-extrabold'>
          Témoignages
        </h1>
        <h2 className='text-gray-400 text-xl'>
          Des retours authentiques sur l'expérience du yoga aérien
        </h2>

        </div>
        <div className="grid lg:grid-cols-3 grid-rows-auto gap-8 mt-8 view ">
          {testimonials.map((testimonial, index) => (
            <div key={index} className="up-block lg:w-[auto] w-full h-fill drop-shadow-xl bg-blue-600 p-8 rounded-lg">
              <div className='flex gap-5 flex-col justify-between h-full '>
                <h2 className='lg:text-lg text-white text-bold'>
                  {testimonial.text}
                </h2>
                <h3 className='font-extrabold text-blue-200 text-lg'>
                  {testimonial.author}
                </h3>
              </div>
            </div>
          ))}
        </div>
      
    </div>
  );
};

export default Testimonials;
