import React from "react";
import Tarifs from "../components/Tarifs";
import Button from "../components/Button";
import Citation from "../components/Citation";
import ParallaxImage from "../components/ParallaxImage";
import '../styles/App.css';

const MesCours = () => {
  return (
    <div className="flex flex-col justify-center items-center h-[auto]">
      {/* section hero */}
      <div className="p-4 lg:px-[5%]  2xl:px-[25%] lg:mt-[200px] mt-[50%] items-center  text-center gap-[50px] flex align-middle flex-col">
        <div className="animate__animated animate__slideInDown flex flex-col gap-8 lg:w-[80%] mx-[auto] ">
          <h1 className="text-[1.7rem] md:text-[3.5rem] lg:leading-[4.2rem] font-extrabold">
          Des <span className=" from-blue-100 to-pink-50 bg-gradient-to-br text-blue-500 py-2 px-8 rounded-full text-[1.7rem] md:text-[3.3rem] lg:leading-[4.2rem] font-extrabold">Cours</span> adaptés à vos besoins et à votre <span className=" text-[1.7rem] md:text-[3.5rem] lg:leading-[4.2rem] font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-blue-500 to-green-500  ">
            emploi du temps
              </span>.
          </h1>
          <h2 className="text-gray-400 text-xl ">
            découvrez nos séances de bien-être pour le corps et l’esprit
          </h2>
        </div>

        <div className="animate__animated animate__slideInUp rounded-xl lg:h-[70vh] overflow-hidden">
          <img className="object-cover w-full" src="./assets/Header-image.png" alt="yoga-studio" />
        </div>
      </div>


            {/* mes horraires */}
            <div className=" bg-blue-200 rounded-xl w-full  px-4  py-[8%] lg:mt-[100px] mt-[20%]">
  <div className="lg:w-[60%] mx-[auto] flex flex-col justify-center items-center text-center">
          <h2 className="text-3xl font-bold lg;mr-10 mb-10 up-block ">Mes horaires</h2>
          <ul className=" text-gray-700 flex flex-row justify-center up-block items-center flex-wrap gap-8">
            <li className="text-xl lg:w-[50%] leading-loose font-medium  px-6 py-4 shadow-lg up-block bg-white rounded-3xl">Tous les matins de <span className="text-2xl font-bold text-blue-700">10h à 11h</span></li>
            <li className="text-xl lg:w-[50%] leading-loose font-medium px-6 py-4 shadow-lg up-block bg-white rounded-3xl">Tous les soirs de <span className="text-2xl font-bold text-blue-700">19h à 20h</span></li>
          </ul>
        </div>
        </div>

{/* mes cous details */}

<div className=" px-4 lg:px-[5%] lg:pt-[10%] lg:pb-[10%] py-10 2xl:px-[25%]">
        <div className="gap-6 lg:gap-[80px] flex flex-col">
        {/* -----1st------ */}
          <div className="grid lg:grid-cols-4 items-start lg:space-x-8 up-block">
            <div className=" col-span-2 flex flex-col gap-[60px] mb-6 lg:mb-0">
              <div className="flex flex-col gap-4">
                <h1 className="text-[1.6rem] md:text-[2.2rem] font-bold">
                  Débutant, Première approche
                </h1>
                <p className="text-gray-400 text-xl">
                  Ce cours est destiné aux personnes qui découvrent le yoga
                  aérien pour la première fois. Nous commençons par les bases,
                  en apprenant à utiliser le hamac de yoga et en explorant des
                  postures simples. L'accent est mis sur la sécurité, la bonne
                  alignement et la compréhension des principes fondamentaux du
                  yoga aérien. Vous apprendrez à vous sentir à l’aise en l’air
                  et à développer la confiance nécessaire pour progresser.
                </p>
              </div>
              <div>
                <Button label={"Réserver dés maintenant"} customLink={"https://buy.stripe.com/6oEcQcbPhf5vfZe9AR"} />
              </div>
            </div>
            <div className=" col-span-2 ">
              <img
                className="w-full h-full object-cover rounded-lg"
                src="./assets/image2.png"
                alt="Débuter le Yoga Aérien"
              />
            </div>
          </div>

          {/* -----2nd------ */}

          <div className="grid lg:grid-cols-4 items-start lg:space-x-8 up-block">

          <div className="  col-span-2 flex flex-col gap-[40px] max-sm:mb-6 lg:hidden ">
              <div className="animate__animated animate__fadeInDown flex flex-col gap-4">
                <h1 className="text-[1.6rem] md:text-[2.2rem] font-bold">
                  Intermédiaire
                </h1>
                <p className="text-gray-400 text-xl">
                  Ce cours est conçu pour ceux qui ont déjà une expérience de
                  base en yoga aérien. Nous approfondissons les postures et
                  techniques acquises dans le cours débutant et introduisons des
                  mouvements plus complexes. Vous travaillerez sur la force, la
                  flexibilité et l’endurance tout en explorant des variations
                  plus avancées des postures de yoga aérien. Ce niveau permet
                  également de se concentrer davantage sur la fluidité et la
                  transition entre les postures.
                </p>
              </div>
              <div>
                <Button label={"Réserver dés maintenant"} customLink={"https://buy.stripe.com/14k2by5qT0aBaEUeV1"} />
              </div>
            </div>


            <div className="col-span-2 max-sm:mb-6 lg:mb-0">
              <img
                className="w-full h-full object-cover rounded-lg"
                src="./assets/image3.png"
                alt="Intermdediare dans le Yoga Aérien"
              />
            </div>

            <div className=" col-span-2 flex flex-col gap-[40px] max-sm:hidden ">
              <div className="flex flex-col gap-4">
                <h1 className="text-[1.6rem] md:text-[2.2rem] font-bold">
                  Intermédiaire
                </h1>
                <p className="text-gray-400 text-xl">
                  Ce cours est conçu pour ceux qui ont déjà une expérience de
                  base en yoga aérien. Nous approfondissons les postures et
                  techniques acquises dans le cours débutant et introduisons des
                  mouvements plus complexes. Vous travaillerez sur la force, la
                  flexibilité et l’endurance tout en explorant des variations
                  plus avancées des postures de yoga aérien. Ce niveau permet
                  également de se concentrer davantage sur la fluidité et la
                  transition entre les postures.
                </p>
              </div>
              <div>
                <Button label={"Réserver dés maintenant"} customLink={"https://buy.stripe.com/14k2by5qT0aBaEUeV1"} />
              </div>
            </div>
          </div>

          {/* 3rd */}
          <div className="grid lg:grid-cols-4 items-start lg:space-x-8 up-block">
            <div className=" col-span-2 flex flex-col gap-[60px] mb-6 lg:mb-0 ">
              <div className="flex flex-col gap-4">
                <h1 className="text-[1.6rem] md:text-[2.2rem] font-bold">
                  Avancé
                </h1>
                <p className="text-gray-400 text-xl">
                  Pour les pratiquants expérimentés, ce cours propose des défis
                  plus élevés. Nous explorons des enchaînements de postures
                  complexes, des inversions et des figures acrobatiques. Ce
                  niveau demande une bonne maîtrise des techniques de base et
                  intermédiaires ainsi qu’une excellente condition physique.
                  Vous développerez votre agilité, votre force et votre contrôle
                  tout en expérimentant les aspects les plus dynamiques du yoga
                  aérien.
                </p>
              </div>
              <div>
                <Button label={"Réserver dés maintenant"} customLink={"https://buy.stripe.com/dR6bM81aD1eFfZe8wJ"} />
              </div>
            </div>
            <div className=" col-span-2 ">
              <img
                className="w-full h-full object-cover rounded-lg"
                src="./assets/image4.png"
                alt="Découvrez le Yoga Aérien"
              />
            </div>
          </div>

          {/* 4th */}
          <div className="grid lg:grid-cols-4 items-start lg:space-x-8 up-block">

          <div className=" col-span-2 flex flex-col gap-[60px] max-sm:mb-6 lg:hidden lg:mb-0">
              <div className="flex flex-col gap-4">
                <h1 className="text-[1.6rem] md:text-[2.2rem] font-bold">
                  Parents-Enfants, Un moment en famille
                </h1>
                <p className="text-gray-400 text-xl">
                  Ce cours est conçu pour offrir aux parents et aux enfants une
                  opportunité de partager une activité ludique et bénéfique.
                  Ensemble, vous découvrirez des postures de yoga aérien
                  adaptées à tous les âges et niveaux de forme physique. Ce
                  moment en famille permet de renforcer les liens, d’encourager
                  la collaboration et de s’amuser tout en bénéficiant des
                  bienfaits du yoga. C'est une excellente manière d’initier les
                  enfants à la pratique du yoga dans un environnement sûr et
                  stimulant.
                </p>
              </div>
              <div>
                <Button label={"Réserver dés maintenant"} customLink={"https://buy.stripe.com/14k2by5qT0aBaEUeV1"} />
              </div>
            </div>


            <div className=" col-span-2 ">
              <img
                className="w-full h-full object-cover rounded-lg"
                src="./assets/image5.png"
                alt="Découvrez le Yoga Aérien"
              />
            </div>


            <div className=" col-span-2 flex flex-col gap-[60px] mb-6 max-sm:hidden lg:mb-0">
              <div className="flex flex-col gap-4">
                <h1 className="text-[1.6rem] md:text-[2.2rem] font-bold">
                  Parents-Enfants, Un moment en famille
                </h1>
                <p className="text-gray-400 text-xl">
                  Ce cours est conçu pour offrir aux parents et aux enfants une
                  opportunité de partager une activité ludique et bénéfique.
                  Ensemble, vous découvrirez des postures de yoga aérien
                  adaptées à tous les âges et niveaux de forme physique. Ce
                  moment en famille permet de renforcer les liens, d’encourager
                  la collaboration et de s’amuser tout en bénéficiant des
                  bienfaits du yoga. C'est une excellente manière d’initier les
                  enfants à la pratique du yoga dans un environnement sûr et
                  stimulant.
                </p>
              </div>
              <div>
                <Button label={"Réserver dés maintenant"} customLink={"https://buy.stripe.com/14k2by5qT0aBaEUeV1"} />
              </div>
            </div>
          </div>
        </div>
</div>

{/* Citation de yoga */}
<Citation />

{/* Tarif */}
<Tarifs />

{/* ParallaxImage*/}
<ParallaxImage />
    </div>
  );
};

export default MesCours;
